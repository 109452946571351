import { ClubLensInfo, convertDateToStr } from "types/ClubLensInfo";
import { requestGet, requestPost ,requestDelete } from "utils/axios";

interface ApiClubLensInfo{
    lens_type_cd: string;
    start_date: string;
    exchange_date: string;
    daybefore_notice_datetime: string;
    today_notice_datetime: string;
    calendar_display_flg: number;
}
interface PostDaleteInfo{
}

const convertClubLensInfo = (data: ApiClubLensInfo): ClubLensInfo => {
    return {
        lens_type_cd: data.lens_type_cd,
        start_date: convertDate(data.start_date),
        exchange_date: convertDate(data.exchange_date),
        daybefore_notice_datetime: data.daybefore_notice_datetime,
        today_notice_datetime: data.today_notice_datetime,
        calendar_display_flg: data.calendar_display_flg,
    }
}
const convertApiClubLensInfo = (clubLensInfo: ClubLensInfo): ApiClubLensInfo => {
    return {
        lens_type_cd: clubLensInfo.lens_type_cd,
        start_date: convertDateToStr(clubLensInfo.start_date),
        exchange_date: convertDateToStr(clubLensInfo.exchange_date),
        daybefore_notice_datetime: clubLensInfo.daybefore_notice_datetime,
        today_notice_datetime: clubLensInfo.today_notice_datetime,
        calendar_display_flg: clubLensInfo.calendar_display_flg,
    }
}
export const convertDate = (dataStr: string): Date =>{
    return new Date(parseInt(dataStr.substring(0, 4)), parseInt(dataStr.substring(5, 7)) - 1, parseInt(dataStr.substring(8, 10)));
}

export const getClubLensInfo = async (): Promise<ClubLensInfo | undefined> => {
    const { data } = await requestGet<ApiClubLensInfo>(`/get-club-lens-info`);
    if (data) {
        return convertClubLensInfo(data);
    }
    return undefined;
}

export const saveClubLensInfo = async (clubLensInfo: ClubLensInfo): Promise<void> => {
    const apiClubLensInfo = convertApiClubLensInfo(clubLensInfo);
    await requestPost<PostDaleteInfo, ApiClubLensInfo>(`/set-club-lens-info`, apiClubLensInfo);
}
export const deleteClubLensInfo = async (): Promise<void> => {
    await requestDelete<PostDaleteInfo>(`/delete-club-lens-info`, undefined);
}
export const checkAuthError = (response: any):boolean => {
    return response.status === 401 || response.status === 403;
}