import { FC, useEffect, useState } from "react";

interface Props {
  authError: boolean;
  accrualDatetime: Date | undefined;
  pageShow: Date | undefined;
  setPageShow: (date:Date) => void;
}

export const Error: FC<Props> = ({ authError, accrualDatetime, pageShow, setPageShow }) => {
  const errorMessage = !authError;
  const [time, setTime] = useState<Date>(new Date());
  const [now, setNow] = useState<Date>();
  useEffect(() => {
    if (accrualDatetime) {
      setTime(accrualDatetime);
    }
  }, [accrualDatetime])
  useEffect(() => {
    const timer = setInterval(() => {
      if (time) {
        setNow(new Date());
      }
    }, 100);
    return () => clearInterval(timer);
  }, [])
  useEffect(() => {
    if (now && time) {
      if (now.getTime() - time.getTime() > 2000) {
        if (pageShow && now.getTime() - pageShow.getTime() > 5000) {
          setPageShow(now);
        }
      }
      setTime(now);
    }
  }, [now, time])
  const updateClick = () => {
    setPageShow(new Date());
  }

  return (
    <>
    {
      errorMessage
      ?(
        <div className="app-box is-error-app">
          <div className="error-box is-error-message">
            <div className="error-box-image">
              <img src="./images/26_melsuke.png" alt="メル助（エラー）" />
            </div>
            <div className="error-box-title">
              ページが表示できません
            </div>
            <div className="error-box-text">
            「更新する」ボタンを押下してもエラーが解決されない場合、お手数ですがしばらく時間を置いてから再度操作していただけますようお願いいたします</div>
          </div>
          <div className="app-box-button">
            <div className="button-wrap">
              <div className='button-label' onClick={updateClick}>更新する</div>
            </div>
          </div>
        </div>
      )
      :(
        <div className="app-box is-error-app">
          <div className="error-box">
            <div className="error-box-title">
              本コンテンツは<br />
              Club Menicon 会員限定です。
            </div>
            <div className="error-box-text">
              Club Menicon会員の方は<br />
              「その他 &gt; <a href={process.env.REACT_APP_MYPAGE_URL}>ログイン・会員登録</a>」より<br />
              ログインを行ってください。
            </div>
          </div>
        </div>
      )
    }
    </>
  );
}

export default Error;
