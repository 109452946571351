import { FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

type Props = {
  showFlag?: boolean
  setShowModalHelp: (arg: boolean) => void;
}

export const ModalHelp: FC<Props> = (props) => {
  const closeModal = () =>{
    props.setShowModalHelp(false);
  }
  return (
    <>
      {
        props.showFlag ? (
          <div className="modal-wrap">
            <div className="modal-overlay" onClick={closeModal}/>
            <div className="modal-contents">
              <div className="modal-heading">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-help" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="12" cy="12" r="9"></circle>
                  <line x1="12" y1="17" x2="12" y2="17.01"></line>
                  <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4"></path>
                </svg>
                  ヘルプ
              </div>
              <div className="modal-body help-body">
                <div className="help-wrap">
                  <div className="help-heading">
                    <img src='./images/help_image_min.png' />
                  </div>
                  <Tabs className="help-contents tab-wrap">
                    <TabList className='tab-index'>
                      <Tab className="is-selected">カレンダー</Tab>
                      <Tab>レンズ<br />タイプ</Tab>
                      <Tab>使用<br />開始日</Tab>
                      <Tab>交換日</Tab>
                      <Tab>通知</Tab>
                    </TabList>

                    <TabPanel className="tab-body-wrap">
                      <div className="tab-body">
                        カレンダーを参照しながら「使用開始日」「交換日」を設定することができます<br /><br />
                        カレンダーはご使用の端末サイズによって初期の表示/非表示が異なります<br />
                        表示状態も「カレンダーを表示する/非表示にする」の選択後「設定する」で保存され、次回以降のアクセスで表示に反映されます<br />
                        ※「初期化」を実行された場合はカレンダーの表示状態も初期化されます
                      </div>
                    </TabPanel>
                    <TabPanel className="tab-body-wrap">
                      <div className="tab-body">
                        お客様のご使用されるコンタクトレンズ種類に合わせ、2WEEK、1MONTH、3MONTHよりご選択ください
                      </div>
                    </TabPanel>
                    <TabPanel className="tab-body-wrap">
                      <div className="tab-body">
                      ご使用のコンタクトレンズを開封された日を設定してください<br />
                      ※過去日を設定することは可能ですが、設定されたレンズタイプの最長使用期間を超える設定はできません<br />
                      ※未来日を設定することは可能ですが、本日より1ヵ月を超える設定はできません
                      </div>
                    </TabPanel>
                    <TabPanel className="tab-body-wrap">
                      <div className="tab-body">
                      設定されたレンズタイプの最長使用期間終了翌日が自動で表示されます<br />
                      使用期間の間であれば任意で交換日を変更できますが、最長使用期間を超える範囲での設定はできません<br />
                      また、1MONTH、3MONTHのコンタクトレンズは使用開始日の翌月同一日がコンタクトレンズ交換日となりますのでご注意ください<br /><br />
                      例）<br />
                      ・「1MONTH」を選択し使用開始日が1月1日の場合、交換日は2月1日となります<br /><br />
                      ・「1MONTH」を選択し使用開始日が1月31日の場合、交換日は2月28日となります<br />
                      </div>
                    </TabPanel>
                    <TabPanel className="tab-body-wrap">
                      <div className="tab-body">
                      設定いただいた時間にプッシュ通知が送られます<br />
                      プッシュ通知をご希望の場合、端末の通知設定をオンに切り替えてください<br />
                      端末の通知設定がオフになっている場合は通知が送られませんので、ご注意ください
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
              <div className="modal-footer">
                <div className="app-box-button" onClick={closeModal}>
                  <div className="button-wrap">
                    <div className='button-label'>閉じる</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ("")
      }
    </>
  )
}
