import { FC, useState } from 'react';

type Props = {
  showFlag?: boolean
  setShowModalReset: (arg: boolean) => void;
  initializeProcess: (func: () => void) => void;
}

export const ModalReset: FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const closeModal = () =>{
    props.setShowModalReset(false);
  }

  const initialize = () => {
    setLoading(true);
    props.initializeProcess(() => { setLoading(false) });
  }

  return (
    <>
      {
        props.showFlag ? (
          <>
            {
              loading ?
                <div className="modal-wrap">
                  <div className="modal-overlay"/>
                  <div className="modal-contents">
                    <div className="modal-heading">
                      設定の初期化
                    </div>
                    <div className="modal-body">
                      レンズ交換情報を削除し、初期状態にリセットします<br />
                      初期化しますか？
                    </div>
                    <div className="modal-footer">
                      <div className="app-box-button loading-button">
                        <div className='loading-bar-wrap'>
                          <div className="loading-race-by"></div>
                        </div>
                        <div className="button-wrap is-disabled">
                          <div className='button-label'>キャンセル</div>
                        </div>
                        <div className="button-wrap">
                          <div className='button-label-icon'>
                            <svg
                              className="loading-ring"
                              viewBox="25 25 50 50"
                              strokeWidth="5"
                            >
                              <circle cx="50" cy="50" r="20" />
                            </svg>
                          </div>
                          <div className='button-label'>
                            初期化中...
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              :
                <div className="modal-wrap">
                  <div className="modal-overlay" onClick={closeModal}/>
                  <div className="modal-contents">
                    <div className="modal-heading">
                      設定の初期化
                    </div>
                    <div className="modal-body">
                      レンズ交換情報を削除し、初期状態にリセットします<br />
                      初期化しますか？
                    </div>
                    <div className="modal-footer">
                      <div className="app-box-button">
                        <div className="button-wrap" onClick={closeModal}>
                          <div className='button-label'>キャンセル</div>
                        </div>
                        <div className="button-wrap" onClick={initialize}>
                          <div className='button-label'>初期化</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </>
        ) : ("")
      }
    </>
  )
}
