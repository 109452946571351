import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const PREFER_STATUS = process.env.REACT_APP_PREFER_STATUS;

const setOptions = (
): AxiosRequestConfig => {
    const options: AxiosRequestConfig = {};
    options.headers = {
        "Accept":"application/json",
        "Content-Type":"application/json"
    };

    if (PREFER_STATUS) {
        options.headers.Prefer = `status=${PREFER_STATUS}`;// ローカルモックサーバテスト用
    }
    options.baseURL = BASE_URL;
    if (BASE_URL?.startsWith("https")) {
        options.withCredentials = true;
    } 

    return options;
};

export const requestGet = async <T>(
    url: string,
): Promise<AxiosResponse<T>> => {
    const options = setOptions();

    return axios.get<T>(url, options);
};

export const requestPost = async <T, U = undefined>(
    url: string,
    payload: U,
): Promise<AxiosResponse<T>> => {
    const options = setOptions();

    return axios.post<T>(url, payload, options);
};

export const requestDelete = async <T, U = undefined>(
    url: string,
    payload: U,
): Promise<AxiosResponse<T>> => {
    const options = setOptions();
    options.data = payload;

    return axios.delete<T>(url, options);
};