import { FC } from 'react';

type Props = {
  showFlag?: boolean
  setShowModalInitialize: (arg: boolean) => void;
}

export const ModalInitialize: FC<Props> = (props) => {
  const closeModal = () =>{
    props.setShowModalInitialize(false);
  }
  return (
    <>
      {
        props.showFlag ? (
          <div className="modal-wrap">
            <div className="modal-overlay" onClick={closeModal}/>
            <div className="modal-contents">
              <div className="modal-heading">
                レンズ交換日を設定する
              </div>
              <div className="modal-body">
                レンズ交換機能を使用するには、必要情報を入力してください
              </div>
              <div className="modal-footer">
                <div className="app-box-button">
                  <div className="button-wrap" onClick={closeModal}>
                    <div className='button-label'>必要情報を入力する</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ("")
      }
    </>
  )
}
