import { FC } from 'react';

type Props = {
  showFlag?: boolean
  setShowModalSaveConfirmation: (arg: boolean) => void;
  backProcess: () => void;
}

export const ModalSaveConfirmation: FC<Props> = (props) => {
  const closeModal = () =>{
    props.setShowModalSaveConfirmation(false);
  }
  return (
    <>
      {
        props.showFlag ? (
          <div className="modal-wrap">
            <div className="modal-overlay" onClick={closeModal}/>
            <div className="modal-contents">
              <div className="modal-heading">
              設定が保存されていません
              </div>
              <div className="modal-body">
              設定内容が保存されていません<br />変更を破棄して元の画面に戻りますか？
              </div>
              <div className="modal-footer">
                <div className="app-box-button">
                  <div className="button-wrap" onClick={closeModal}>
                    <div className='button-label'>キャンセル</div>
                  </div>
                  <div className="button-wrap" onClick={() => {closeModal(); props.backProcess();}}>
                    <div className='button-label'>戻る</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ("")
      }
    </>
  )
}
