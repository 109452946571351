import React from 'react'

function Loader() {
  return (
    <>
      <div className="loader-wrap">
        <div className="loader-outline">
          <div className="loader-body">
            <div className="loader-image">
              <img src="./images/30_melsuke_setup.png" alt="メル助（読み込み中）" />
            </div>
            <div className="loader-text">レンズ交換の画面を<br/>読み込み中です...</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Loader

