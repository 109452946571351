import { FC, useEffect, useState } from "react";
import { ModalDate, ModalDateProp }  from "../components/modal/ModalDate"
import { ModalTime, ModalTimeProp } from "../components/modal/ModalTime"
import { ModalHelp } from "../components/modal/ModalHelp"
import { ModalInitialize } from "../components/modal/ModalInitialize"
import { ModalSaveConfirmation } from "../components/modal/ModalSaveConfirmation"
import { ModalCalendar, ModalCalendarProp }  from "../components/modal/ModalCalendar"
import { ClubLensInfo, getDaybeforeTime, getTodayTime, calcExchangeDateMax, calcStartDateMin, convertDateToStr } from 'types/ClubLensInfo';
import { saveClubLensInfo, checkAuthError } from 'utils/apis/ClubLensInfo'
import { ModalMessage, ModalMessageProp } from "../components/modal/ModalMessage"

interface Props {
  clubLensInfo: ClubLensInfo;
  firstAccess: boolean;
  setClubLensInfo: (clubLensInfo: ClubLensInfo) => void;
  setCurrentPpage: (currentPpage: "" | "TOP" | "SETTING" | "ERROR") => void;
  setFirstAccess: (firstAccess: boolean) => void;
  setAuthErrorTrue: () => void;
  showComment: (msg: string) => void;
}

const weekOfDays = [...Array(7)].map((_: undefined, idx: number) => idx);
const datesSeed = [...Array(42)].map((_: undefined, idx: number) => idx + 1);
const initializeClickErrorMsg = "設定でエラーが発生しました";

const dateDiff = (startDate: Date, exchangeDate: Date): number => {
  return Math.floor((exchangeDate.getTime() - startDate.getTime()) / 86400000);
}
const createDateArray = (minDate: Date, maxDate: Date) => {
  const dateArray: Date[] = [];
  for (const d = minDate; d <= maxDate; d.setDate(d.getDate() + 1)) {
    dateArray.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
  }
  return dateArray;
}
const getExchangeText = (lensTypeCd: string):string => {
  switch (lensTypeCd) {
    case "1":
      return "2週間";
    case "2":
      return "1ヵ月";
    case "3":
      return "3ヵ月";
  }
  return "";
}

export const Setting: FC<Props> = ({ clubLensInfo, firstAccess, setClubLensInfo, setCurrentPpage, setFirstAccess, setAuthErrorTrue, showComment }) => {
  //画面項目
  const [lensTypeCd, setLensTypeCd] = useState(clubLensInfo.lens_type_cd);
  const [startDate, setStartDate] = useState(clubLensInfo.start_date);
  const [exchangeDate, setExchangeDate] = useState(clubLensInfo.exchange_date);
  const [daybeforeNotice, setDaybeforeNotice] = useState(clubLensInfo.daybefore_notice_datetime ? true : false);
  const [daybeforeNoticeTime, setDaybeforeNoticeTime] = useState(getDaybeforeTime(clubLensInfo));
  const [todayNotice, setTodayNotice] = useState(clubLensInfo.today_notice_datetime ? true : false);
  const [todayNoticeTime, setTodayNoticeTime] = useState(getTodayTime(clubLensInfo));
  //内部項目
  const [exchangeDays, setExchangeDays] = useState(dateDiff(clubLensInfo.start_date, clubLensInfo.exchange_date));
  const [daybeforeNoticeDate, setDaybeforeNoticeDate] = useState(new Date(clubLensInfo.exchange_date.getFullYear(), clubLensInfo.exchange_date.getMonth(), clubLensInfo.exchange_date.getDate() - 1));
  const [exchangeDateMax, setExchangeDateMax] = useState(calcExchangeDateMax(clubLensInfo.lens_type_cd, clubLensInfo.start_date));

  const daybeforeNoticeToggle = () => {
    setDaybeforeNotice(!daybeforeNotice);
  }
  const todayNoticeToggle = () => {
    setTodayNotice(!todayNotice);
  }

  //開始日・交換日関連
  useEffect(() => {
    const exchangeDateMax = calcExchangeDateMax(lensTypeCd, startDate);
    setExchangeDate(exchangeDateMax);
    setExchangeDateMax(exchangeDateMax);
  }, [lensTypeCd])
  useEffect(() => {
    const tmpExchangeDateMax = calcExchangeDateMax(lensTypeCd, startDate);
    if (exchangeDate.getTime() === exchangeDateMax.getTime()) {
      setExchangeDate(tmpExchangeDateMax);
    } else {
      const tmpExchangeDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + exchangeDays);
      setExchangeDate(tmpExchangeDate < tmpExchangeDateMax ? tmpExchangeDate : tmpExchangeDateMax);
    }
    setExchangeDateMax(tmpExchangeDateMax);
  }, [startDate])
  useEffect(() => {
    setExchangeDays(dateDiff(startDate, exchangeDate))
    setDaybeforeNoticeDate(new Date(exchangeDate.getFullYear(), exchangeDate.getMonth(), exchangeDate.getDate() - 1));
  }, [exchangeDate])
  const lensTypeCdClick = (lensTypeCdNew: string) =>{
    if (lensTypeCd === lensTypeCdNew) {
      return;
    }
    const now = new Date();
    const minDate = calcStartDateMin(lensTypeCdNew, now);
    if (startDate < minDate) {
      setStartDate(minDate);
    }
    setLensTypeCd(lensTypeCdNew);
  }
  
  //モーダル
  const [showCalendar, setShowCalendar] = useState(clubLensInfo.calendar_display_flg === 0 ? true : false);
  useEffect(() =>{
    setShowCalendar(clubLensInfo.calendar_display_flg === 0 ? true : false);
  }, [clubLensInfo])
  const calendarToggle = () =>{
    setShowCalendar(!showCalendar);
  }

  const [ showModalCalendar, setShowModalCalendar ] = useState(false);
  const [showModalDate, setShowModalDate ] = useState(false);
  const [showModalTime, setShowModalTime] = useState(false);

  const [modalDateProp, setModalDateProp] = useState<ModalDateProp>({ date: new Date(), dateArray: [], setDate: setStartDate, title: "" });
  const [modalTimeProp, setModalTimeProp] = useState<ModalTimeProp>({ time: "", setTime: setDaybeforeNoticeTime, title: "" });

  const startDateClick = () => {
    const now = new Date();
    const minDate = calcStartDateMin(lensTypeCd, now);
    let dateTmp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
    const maxDate = dateTmp.getDate() === now.getDate() ? dateTmp : new Date(dateTmp.getFullYear(), dateTmp.getMonth(), 0);
    const dateArray = createDateArray(minDate, maxDate);

    setShowModalDate(true);
    setModalDateProp({
      date: startDate,
      dateArray: dateArray,
      setDate: setStartDate,
      title: "使用開始日"
    })
  }
  const exchangeDateClick = () => {
    const minDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
    const dateArray = createDateArray(minDate, exchangeDateMax);

    setShowModalDate(true);
    setModalDateProp({
      date: exchangeDate,
      dateArray: dateArray,
      setDate: setExchangeDate,
      title: "交換日"
    })
  }

  const daybeforeNoticeTimeClick = () => {
    setShowModalTime(true);
    setModalTimeProp({
      time: daybeforeNoticeTime, 
      setTime: setDaybeforeNoticeTime, 
      title: "前日通知時間"
    })
  }
  const todayNoticeTimeClick = () => {
    setShowModalTime(true);
    setModalTimeProp({
      time: todayNoticeTime,
      setTime: setTodayNoticeTime,
      title: "当日通知時間"
    })
  }

  //カレンダー
  const now = new Date();
  const [modalCalendarProp, setModalCalendarProp] = useState<ModalCalendarProp>({ showStart: false, showExchange: false, calendarDate: now, top: 0, left: 0 });
  const [currentMonth, setCurrentMonth] = useState({ year: now.getFullYear(), month: now.getMonth()});
  const [dates, setDates] = useState<Date[]>([])
  useEffect(() => {
    const firstDayOfMonth = new Date(currentMonth.year, currentMonth.month, 1).getDay();
    const dateList = datesSeed.map((date) => { return new Date(currentMonth.year, currentMonth.month, date - firstDayOfMonth) });
    setDates(dateList);
  }, [currentMonth]);

  const calendarPrevClick = () => {
    const prevMonth = new Date(currentMonth.year, currentMonth.month - 1, 1)
    setCurrentMonth({ year: prevMonth.getFullYear(), month: prevMonth.getMonth() });
  };
  const calendarNextClick = () => {
    const nextMonth = new Date(currentMonth.year, currentMonth.month + 1, 1)
    setCurrentMonth({ year: nextMonth.getFullYear(), month: nextMonth.getMonth() });
  };
  const checkStartDate = (date: Date):boolean => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return calcExchangeDateMax(lensTypeCd, date) >= today && date <= new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
  }
  const checkExchangeDate = (date: Date): boolean => {
    return date <= exchangeDateMax && date > startDate;
  }
  const calendarDateClick = (calendarDate: Date) => {
    return (event: any) => {
      const canStart = checkStartDate(calendarDate);
      const canExchange = checkExchangeDate(calendarDate);
      if (canStart || canExchange) {
        setModalCalendarProp({ showStart: canStart, showExchange: canExchange, calendarDate: calendarDate, top: event.target.offsetTop, left: event.target.offsetLeft });
        setShowModalCalendar(true);
      }
    }
  };

  const getExchangeDays = ():string => {
    if (exchangeDate === exchangeDateMax) {
      return getExchangeText(lensTypeCd);
    }
    return exchangeDays + "日間"
  }

  //設定するボタン
  //loading
  const [loading, setLoading] = useState(false);
  const settingClick = () => {
    if (loading || !checkStartDate(startDate)) {
      return;
    }
    setLoading(true);
    const clubLensInfo = {
      lens_type_cd: lensTypeCd,
      start_date: startDate,
      exchange_date: exchangeDate,
      daybefore_notice_datetime: daybeforeNotice ? `${convertDateToStr(daybeforeNoticeDate)}T${daybeforeNoticeTime}` : "",
      today_notice_datetime: todayNotice ? `${convertDateToStr(exchangeDate)}T${todayNoticeTime}` : "",
      calendar_display_flg: showCalendar ? 0 : 1,
    };
    saveClubLensInfo(clubLensInfo).then(() => {
      setFirstAccess(false);
      setClubLensInfo(clubLensInfo);
      showComment("設定しました！");
      setCurrentPpage("TOP");
    }).catch((error) => {
      if (error.response) {
        if (checkAuthError(error.response)) {
          setAuthErrorTrue();
        } else {
          showMessage(initializeClickErrorMsg, true, false);
        }
      } else {
        showMessage(initializeClickErrorMsg, true, false);
      }
    }).finally(() => {
      setLoading(false);
    });

  }

  //ヘルプ
  const [showModalHelp, setShowModalHelp] = useState(false);
  const showHelp = () => {
    setShowModalHelp(true);
  }

  //トーストメッセージ
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessageProp, setModalMessageProp] = useState<ModalMessageProp>({ message: "", error: false, success: false });
  const showMessage = (message: string, error: boolean, success: boolean) => {
    setModalMessageProp({
      message: message,
      error: error,
      success: success
    });
    setShowModalMessage(true);
  }

  //初期設定モーダル
  const [showModalInitialize, setShowModalInitialize] = useState(firstAccess);

  //保存破棄確認
  const [showModalSaveConfirmation, setShowModalSaveConfirmation] = useState(false);

  const backClick = () => {
    if (checkSettingChange()) {
      setCurrentPpage("TOP");
    } else {
      setShowModalSaveConfirmation(true);
    }
  }
  const checkSettingChange = ():boolean => {
    if (clubLensInfo.lens_type_cd !== lensTypeCd) {
      return false;
    }
    if (convertDateToStr(clubLensInfo.start_date) !== convertDateToStr(startDate)) {
      return false;
    }
    if (convertDateToStr(clubLensInfo.exchange_date) !== convertDateToStr(exchangeDate)) {
      return false;
    }
    if (clubLensInfo.daybefore_notice_datetime !== (daybeforeNotice ? `${convertDateToStr(daybeforeNoticeDate)}T${daybeforeNoticeTime}` : "")) {
      return false;
    }
    if (clubLensInfo.today_notice_datetime !== (todayNotice ? `${convertDateToStr(exchangeDate)}T${todayNoticeTime}` : "")) {
      return false;
    }
    if (clubLensInfo.calendar_display_flg !== (showCalendar ? 0 : 1)) {
      return false;
    }

    return true;
  }

  return (
    <>
      <div className={`app-box is-setting-app ${showModalDate ? "is-modal-open" : ""}`} >
        <div className="app-box-body">
        <div className="app-box-menu">
          {!firstAccess && 
            <div className="menu-box back-button-menu" onClick={backClick}>
              <div className="menu-box-icon">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back-up" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1"></path>
              </svg>
              </div>
              <div className="menu-box-label">戻る</div>
            </div>
          }
          {
            !showCalendar
            ?(
              <div className="menu-box calender-menu" onClick={calendarToggle}>
                <div className="menu-box-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <rect x="4" y="5" width="16" height="16" rx="2"></rect>
                    <line x1="16" y1="3" x2="16" y2="7"></line>
                    <line x1="8" y1="3" x2="8" y2="7"></line>
                    <line x1="4" y1="11" x2="20" y2="11"></line>
                    <line x1="11" y1="15" x2="12" y2="15"></line>
                    <line x1="12" y1="15" x2="12" y2="18"></line>
                  </svg>
                </div>
                <div className="menu-box-label">カレンダーを<br/>表示する</div>
              </div>
            ):(
              <div className="menu-box calender-menu" onClick={calendarToggle}>
                <div className="menu-box-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M19.823 19.824a2 2 0 0 1 -1.823 1.176h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 1.175 -1.823m3.825 -.177h9a2 2 0 0 1 2 2v9"></path>
                    <line x1="16" y1="3" x2="16" y2="7"></line>
                    <line x1="8" y1="3" x2="8" y2="4"></line>
                    <path d="M4 11h7m4 0h5"></path>
                    <line x1="11" y1="15" x2="12" y2="15"></line>
                    <line x1="12" y1="15" x2="12" y2="18"></line>
                    <line x1="3" y1="3" x2="21" y2="21"></line>
                  </svg>
                </div>
                <div className="menu-box-label">非表示<br/>にする</div>
              </div>
            )
          }
          <div className="menu-box" onClick={showHelp}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-help" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="9"></circle>
              <line x1="12" y1="17" x2="12" y2="17.01"></line>
              <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4"></path>
            </svg>
            {/* <div className="menu-box-label">ヘルプ</div> */}
          </div>
        </div>
          {
            !showCalendar
            ?(<div className="calendar-wrap"></div>)
            :(
              <div className="calendar-wrap">
                <div className="calendar-heading">
                    {currentMonth.year}<span className="small-text">年</span>{currentMonth.month + 1}<span className="small-text">月</span>
                </div>
                <div className="calendar-box">
                  <div className="calendar-control-wrap">
                    <div className="calendar-prev" onClick={calendarPrevClick}>
                      <div className="calendar-control-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <polyline points="15 6 9 12 15 18"></polyline>
                        </svg>
                      </div>
                      <div className="calendar-batch-wrap">
                        {
                          (currentMonth.year > startDate.getFullYear() || (currentMonth.year === startDate.getFullYear() && currentMonth.month > startDate.getMonth())) &&
                          <span className="batch-startDot">●</span>
                        }
                        {
                          (currentMonth.year > exchangeDate.getFullYear() || (currentMonth.year === exchangeDate.getFullYear() && currentMonth.month > exchangeDate.getMonth())) &&
                          <span className="batch-renewDot">●</span>
                        }
                        {
                          ((daybeforeNotice && (currentMonth.year > daybeforeNoticeDate.getFullYear() || (currentMonth.year === daybeforeNoticeDate.getFullYear() && currentMonth.month > daybeforeNoticeDate.getMonth())))
                          || (todayNotice && (currentMonth.year > exchangeDate.getFullYear() || (currentMonth.year === exchangeDate.getFullYear() && currentMonth.month > exchangeDate.getMonth())))
                          ) &&
                          <span className="batch-noticeDot">●</span>
                        }
                      </div>
                    </div>
                    <div className="calendar-next has-nextDot" onClick={calendarNextClick}>
                      <div className="calendar-control-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <polyline points="9 6 15 12 9 18"></polyline>
                        </svg>
                      </div>
                      <div className="calendar-batch-wrap">
                        {
                          (currentMonth.year < startDate.getFullYear() || (currentMonth.year === startDate.getFullYear() && currentMonth.month < startDate.getMonth())) &&
                          <span className="batch-startDot">●</span>
                        }
                        {
                          (currentMonth.year < exchangeDate.getFullYear() || (currentMonth.year === exchangeDate.getFullYear() && currentMonth.month < exchangeDate.getMonth())) &&
                          <span className="batch-renewDot">●</span>
                        }
                        {
                          ((daybeforeNotice && (currentMonth.year < daybeforeNoticeDate.getFullYear() || (currentMonth.year === daybeforeNoticeDate.getFullYear() && currentMonth.month < daybeforeNoticeDate.getMonth())))
                              || (todayNotice && (currentMonth.year < exchangeDate.getFullYear() || (currentMonth.year === exchangeDate.getFullYear() && currentMonth.month < exchangeDate.getMonth())))
                          ) &&
                          <span className="batch-noticeDot">●</span>
                        }
                      </div>
                    </div>
                  </div>
                  <ul className="calendar-box-line is-weekday-line">
                    <li>日</li>
                    <li>月</li>
                    <li>火</li>
                    <li>水</li>
                    <li>木</li>
                    <li>金</li>
                    <li>土</li>
                  </ul>
                  {dates.length > 0 &&
                    <ul className="calendar-box-line">
                      { 
                        weekOfDays.map((idx) =>(
                          <li key={0 * 7 + idx} className={`
                            ${dates[0 * 7 + idx].getTime() === startDate.getTime() ? "is-startDate" : ""}
                            ${dates[0 * 7 + idx].getTime() === exchangeDate.getTime() && todayNotice ? "is-noticeDate" : ""}
                            ${dates[0 * 7 + idx].getTime() === daybeforeNoticeDate.getTime() && daybeforeNotice ? "is-noticeDate" : ""}
                            ${dates[0 * 7 + idx].getTime() === exchangeDate.getTime() ? "is-renewDate" : ""}
                            ${dates[0 * 7 + idx].getMonth() !== currentMonth.month ? "is-disabled" : ""}
                          `} onClick={calendarDateClick(dates[0 * 7 + idx])}>
                            {dates[0 * 7 + idx].getDate()}
                          </li>
                        ))
                      }
                    </ul>
                  }
                  {dates.length > 0 &&
                    <ul className="calendar-box-line">
                      { 
                        weekOfDays.map((idx) =>(
                          <li key={1 * 7 + idx} className={`
                            ${dates[1 * 7 + idx].getTime() === startDate.getTime() ? "is-startDate" : ""}
                            ${dates[1 * 7 + idx].getTime() === exchangeDate.getTime() && todayNotice ? "is-noticeDate" : ""}
                            ${dates[1 * 7 + idx].getTime() === daybeforeNoticeDate.getTime() && daybeforeNotice ? "is-noticeDate" : ""}
                            ${dates[1 * 7 + idx].getTime() === exchangeDate.getTime() ? "is-renewDate" : ""}
                            ${dates[1 * 7 + idx].getMonth() !== currentMonth.month ? "is-disabled" : ""}
                          `} onClick={calendarDateClick(dates[1 * 7 + idx])}>
                            {dates[1 * 7 + idx].getDate()}
                          </li>
                        ))
                      }
                    </ul>
                  }
                  {dates.length > 0 &&
                    <ul className="calendar-box-line">
                      { 
                        weekOfDays.map((idx) =>(
                          <li key={2 * 7 + idx} className={`
                            ${dates[2 * 7 + idx].getTime() === startDate.getTime() ? "is-startDate" : ""}
                            ${dates[2 * 7 + idx].getTime() === exchangeDate.getTime() && todayNotice ? "is-noticeDate" : ""}
                            ${dates[2 * 7 + idx].getTime() === daybeforeNoticeDate.getTime() && daybeforeNotice ? "is-noticeDate" : ""}
                            ${dates[2 * 7 + idx].getTime() === exchangeDate.getTime() ? "is-renewDate" : ""}
                            ${dates[2 * 7 + idx].getMonth() !== currentMonth.month ? "is-disabled" : ""}
                          `} onClick={calendarDateClick(dates[2 * 7 + idx])}>
                            {dates[2 * 7 + idx].getDate()}
                          </li>
                        ))
                      }
                    </ul>
                  }
                  {dates.length > 0 &&
                    <ul className="calendar-box-line">
                      { 
                        weekOfDays.map((idx) =>(
                          <li key={3 * 7 + idx} className={`
                            ${dates[3 * 7 + idx].getTime() === startDate.getTime() ? "is-startDate" : ""}
                            ${dates[3 * 7 + idx].getTime() === exchangeDate.getTime() && todayNotice ? "is-noticeDate" : ""}
                            ${dates[3 * 7 + idx].getTime() === daybeforeNoticeDate.getTime() && daybeforeNotice ? "is-noticeDate" : ""}
                            ${dates[3 * 7 + idx].getTime() === exchangeDate.getTime() ? "is-renewDate" : ""}
                            ${dates[3 * 7 + idx].getMonth() !== currentMonth.month ? "is-disabled" : ""}
                          `} onClick={calendarDateClick(dates[3 * 7 + idx])}>
                            {dates[3 * 7 + idx].getDate()}
                          </li>
                        ))
                      }
                    </ul>
                  }
                  {dates.length > 0 && dates[4 * 7].getMonth() === currentMonth.month && 
                    <ul className="calendar-box-line">
                      { 
                        weekOfDays.map((idx) =>(
                          <li key={4 * 7 + idx} className={`
                            ${dates[4 * 7 + idx].getTime() === startDate.getTime() ? "is-startDate" : ""}
                            ${dates[4 * 7 + idx].getTime() === exchangeDate.getTime() && todayNotice ? "is-noticeDate" : ""}
                            ${dates[4 * 7 + idx].getTime() === daybeforeNoticeDate.getTime() && daybeforeNotice ? "is-noticeDate" : ""}
                            ${dates[4 * 7 + idx].getTime() === exchangeDate.getTime() ? "is-renewDate" : ""}
                            ${dates[4 * 7 + idx].getMonth() !== currentMonth.month ? "is-disabled" : ""}
                          `} onClick={calendarDateClick(dates[4 * 7 + idx])}>
                            {dates[4 * 7 + idx].getDate()}
                          </li>
                        ))
                      }
                    </ul>
                  }
                  {dates.length > 0 && dates[5 * 7].getMonth() === currentMonth.month && 
                    <ul className="calendar-box-line">
                      { 
                        weekOfDays.map((idx) =>(
                          <li key={5 * 7 + idx} className={`
                            ${dates[5 * 7 + idx].getTime() === startDate.getTime() ? "is-startDate" : ""}
                            ${dates[5 * 7 + idx].getTime() === exchangeDate.getTime() && todayNotice ? "is-noticeDate" : ""}
                            ${dates[5 * 7 + idx].getTime() === daybeforeNoticeDate.getTime() && daybeforeNotice ? "is-noticeDate" : ""}
                            ${dates[5 * 7 + idx].getTime() === exchangeDate.getTime() ? "is-renewDate" : ""}
                            ${dates[5 * 7 + idx].getMonth() !== currentMonth.month ? "is-disabled" : ""}
                          `} onClick={calendarDateClick(dates[5 * 7 + idx])}>
                            {dates[5 * 7 + idx].getDate()}
                          </li>
                        ))
                      }
                    </ul>
                  }

                  <ModalCalendar
                    modalCalendarProp={modalCalendarProp}
                    showFlag={showModalCalendar}
                    setStartDate={setStartDate}
                    setExchangeDate={setExchangeDate}
                    setShowModalCalendar={setShowModalCalendar}
                  />
                </div>
              </div>
            )
          }
          <div className="setting-wrap">
            <div className="setting-line term-select-line">
              <ul className="term-select">
                <li className={`term-select-button ${lensTypeCd === "1" ? "is-selected" : ""}`} onClick={() => { lensTypeCdClick("1") }}>2WEEK</li>
                <li className={`term-select-button ${lensTypeCd === "2" ? "is-selected" : ""}`} onClick={() => { lensTypeCdClick("2") }}>1MONTH</li>
                <li className={`term-select-button ${lensTypeCd === "3" ? "is-selected" : ""}`} onClick={() => { lensTypeCdClick("3") }}>3MONTH</li>
              </ul>
            </div>
            <div className="setting-line startDate-line">
              <div className="setting-line-label">使用開始日</div>
              <div className="setting-line-body">
                <div className={`startDate-decrease ${checkStartDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 1)) ? "" : "is-disabled"}`} onClick={() => { checkStartDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 1)) && setStartDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 1))}}>－</div>
                <div 
                  className="startDate-day" 
                  onClick={startDateClick}
                >
                  {convertDateToStr(startDate)}
                </div>
                <div className={`startDate-increase ${checkStartDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1)) ? "" : "is-disabled"}`} onClick={() => { checkStartDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1)) && setStartDate(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1)) }}>＋</div>
              </div>
            </div>
            <div className="setting-line renewDate-line">
              <div className="setting-line-label">交換日</div>
              <div className="setting-line-body">
                <div className="renewDate-day">{getExchangeDays()}・</div>
                <div
                  className="renewDate-until-day"
                  onClick={exchangeDateClick}
                >
                  {convertDateToStr(exchangeDate)}
                </div>
                <div className="renewDate-edit" onClick={exchangeDateClick}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                    <line x1="13.5" y1="6.5" x2="17.5" y2="10.5"></line>
                  </svg>
                </div>
              </div>
            </div>
            <div className="setting-line noticeDate-line">
              <div className="setting-line-label">通知</div>
              <div className="setting-line-body noticeDate-wrap">
                <div className="noticeDate-box">
                  <div className="noticeDate-label">前日</div>
                  <div className={`noticeDate-toggle ${daybeforeNotice ? "is-active" : ""}`} onClick={daybeforeNoticeToggle}>
                    {
                      daybeforeNotice 
                        ?(
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-toggle-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="16" cy="12" r="2"></circle>
                            <rect x="2" y="6" width="20" height="12" rx="6"></rect>
                          </svg>
                        )
                        :(
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-toggle-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="8" cy="12" r="2"></circle>
                            <rect x="2" y="6" width="20" height="12" rx="6"></rect>
                          </svg>
                        )
                    }
                  </div>
                  <div className="noticeDate-select">
                    {
                      daybeforeNotice
                      ?(
                        <div 
                          className="noticeDate-select-input"
                          onClick={daybeforeNoticeTimeClick}
                        >
                          {daybeforeNoticeTime}
                          <span>頃</span>
                        </div>
                      )
                      :(<div className="noticeDate-select-input is-disabled">
                          {daybeforeNoticeTime}
                          <span>頃</span>
                        </div>)
                    }
                  </div>
                </div>
                <div className="noticeDate-box">
                  <div className="noticeDate-label">当日</div>
                  <div 
                    className={`noticeDate-toggle ${todayNotice ? "is-active" : ""}`} onClick={todayNoticeToggle}
                  >
                    {
                      todayNotice 
                        ?(
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-toggle-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="16" cy="12" r="2"></circle>
                            <rect x="2" y="6" width="20" height="12" rx="6"></rect>
                          </svg>
                        )
                        :(
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-toggle-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="8" cy="12" r="2"></circle>
                            <rect x="2" y="6" width="20" height="12" rx="6"></rect>
                          </svg>
                        )
                    }
                  </div>
                  <div className="noticeDate-select">
                    <div className="noticeDate-select">
                      {
                        todayNotice
                        ?(
                          <div
                            className="noticeDate-select-input"
                            onClick={todayNoticeTimeClick}
                          >
                          {todayNoticeTime}
                          <span>頃</span> 
                          </div>
                        )
                        :(
                          <div className="noticeDate-select-input is-disabled">
                            {todayNoticeTime}
                            <span>頃</span>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          loading ?
          <>
            <div className="loading-button-wrap">
              <div className="loading-button-overlay" />
              <div className='loading-bar-wrap'>
                <div className="loading-race-by" />
              </div>
            </div>
            <div className="app-box-button loading-button">
              <div className="button-wrap">
                <div className='button-label-icon'>
                  <svg
                    className="loading-ring"
                    viewBox="25 25 50 50"
                    strokeWidth="5"
                  >
                    <circle cx="50" cy="50" r="20" />
                  </svg>
                </div>
                <div className='button-label'>
                設定中...
                </div>
              </div>
            </div>
          </>
          :
            <div className="app-box-button">
              <div className={`button-wrap ${checkStartDate(startDate) ? "" : "is-disabled"}`} onClick={settingClick}>
                <div className='button-label'>設定する</div>
              </div>
            </div>
        }
        <ModalDate
          showFlag={showModalDate}
          setShowModal={setShowModalDate}
          buttonLabel="設定する"
          modalDateProp={modalDateProp}
        />
        <ModalTime
          showFlag={showModalTime}
          setShowModalTime={setShowModalTime}
          buttonLabel="設定する"
          modalTimeProp={modalTimeProp}
        />
        <ModalHelp
          showFlag={showModalHelp}
          setShowModalHelp={setShowModalHelp}
        />
        <ModalMessage
          showFlag={showModalMessage}
          setShowModalMessage={setShowModalMessage}
          modalMessageProp={modalMessageProp}
        />
        <ModalInitialize
          showFlag={showModalInitialize}
          setShowModalInitialize={setShowModalInitialize}
        />
        <ModalSaveConfirmation
          showFlag={showModalSaveConfirmation}
          setShowModalSaveConfirmation={setShowModalSaveConfirmation}
          backProcess={() => { setCurrentPpage("TOP") }}
        />  
      </div>
    </>
  );
}

export default Setting;
