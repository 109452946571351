import { FC, memo, useEffect, useState } from 'react';


export interface ModalCalendarProp{
  showStart: boolean
  showExchange: boolean
  calendarDate: Date
  top: number;
  left: number;
}

type Props = {
  modalCalendarProp: ModalCalendarProp
  showFlag: boolean
  setStartDate: (startDate: Date) => void
  setExchangeDate: (exchangeDate: Date) => void
  setShowModalCalendar: (arg:boolean) => void;
}

export const ModalCalendar: FC<Props> = memo((props) => {
  const [translate, setTranslate] = useState(0);
  useEffect(() => {
    const day =props.modalCalendarProp.calendarDate.getDay();
    if (day === 4) {
      setTranslate(-30);
    } else if (day === 5){
      setTranslate(-80);
    } else if (day === 6) {
      setTranslate(-130);
    } else {
      setTranslate(0);
    }

  }, [props.modalCalendarProp])
  const closeModal = () =>{
    props.setShowModalCalendar(false);
  }
  const startDateClick = () =>{
    props.setStartDate(props.modalCalendarProp.calendarDate);
    props.setShowModalCalendar(false);
  }
  const exchangeDateClick = () => {
    props.setExchangeDate(props.modalCalendarProp.calendarDate);
    props.setShowModalCalendar(false);
  }
  return (
    <>
      {
        props.showFlag
          ? (
            <div className="modal-wrap">
              <div className="modal-overlay" onClick={closeModal}/>
              <div className="modal-contents" style={{ top: props.modalCalendarProp.top -3, left: props.modalCalendarProp.left -3 }}>
                <div className="setDate-day">{props.modalCalendarProp.calendarDate.getDate()}</div>
                <div className="setDate-list" style={translate ? { transform: `translate(${translate}px)`} : {}}>
                  {
                    props.modalCalendarProp.showStart &&
                    <div className="setDate-list-body setDate-start" onClick={startDateClick}>
                      <span className="setDate-list-label">使用開始日にする</span>
                      <span className="setDate-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <polyline points="9 6 15 12 9 18"></polyline>
                        </svg>
                      </span>
                    </div>
                  }
                  {
                    props.modalCalendarProp.showExchange &&
                    <div className="setDate-list-body setDate-renew" onClick={exchangeDateClick}>
                      <span className="setDate-list-label">交換日にする</span>
                      <span className="setDate-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <polyline points="9 6 15 12 9 18"></polyline>
                        </svg>
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
          )
          : ("")
      }
    </>
  )
})