import { FC, useEffect, useRef, useState } from 'react';

export interface ModalTimeProp {
  time: string;
  setTime: (time: string) => void;
  title: string
}
type Props = {
  showFlag?: boolean
  setShowModalTime: (arg: boolean) => void;
  buttonLabel: string
  modalTimeProp: ModalTimeProp
}

export const ModalTime: FC<Props> = (props) => {
  const [hour, setHour] = useState("00")
  const [minute, setMinute] = useState("00")
  const [initDatetime, setInitDatetime] = useState<Date>();
  const hourList = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
  const minuteList = ["00","15","30","45"];

  useEffect(() => {
    const hourTmp = props.modalTimeProp.time.substring(0, 2);
    const minuteTmp = props.modalTimeProp.time.substring(3, 5);
    const hour = hourList.indexOf(hourTmp) < 0 ? "00" : hourTmp;
    const minute = minuteList.indexOf(minuteTmp) < 0 ? "00" : minuteTmp;
    setHour(hour);
    setMinute(minute);
    setInitDatetime(new Date());
  }, [props, props.modalTimeProp]);

  const hourUl = useRef<HTMLUListElement>(null);
  const minuteUl = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (hourUl && hourUl.current) {
      scroll(hourUl, hour);
    }
  }, [hourUl.current, initDatetime])
  useEffect(() => {
    if (minuteUl && minuteUl.current) {
      scroll(minuteUl, minute);
    }
  }, [minuteUl.current, initDatetime])
  const scroll = (ulElement: any, value: string) => {
    const ul = ulElement.current;
    Array.from(ul.children).forEach((li: any) => {
      const dataValue = li.getAttribute("data-value");
      if (value === dataValue) {
        setTimeout(() => {
          ul.scrollTo({
            behavior: "instant",
            top: (li.offsetTop - 112),
          });
        }, 0);
      }
    });
  }

  const hourScroll = (event: any) => {
    scrollFunc(event.target, setHour);
  }
  const minuteScroll = (event: any) => {
    scrollFunc(event.target, setMinute);
  }
  const scrollFunc = (ul: any, setFunc: (val: string) => void) => {
    if (ul) {
      const ulBoundingClientRect = ul.getBoundingClientRect();
      const ulOffsetHeight = ul.offsetHeight;
      const middle = ulBoundingClientRect.top + (ulOffsetHeight / 2);
      Array.from(ul.children).forEach((li: any) => {
        const liBoundingClientRect = li.getBoundingClientRect();
        if (liBoundingClientRect.top < middle && liBoundingClientRect.bottom > middle) {
          const dataValue = li.getAttribute("data-value");
          setFunc(dataValue);
        }
      });
    }
  }

  const settingClick = () => {
    props.modalTimeProp.setTime(hour + ":" + minute);
    props.setShowModalTime(false);
  }
  const closeModal = () => {
    props.setShowModalTime(false);
  }
  return (
    <>
      {
        props.showFlag ? (
          <div className="modal-wrap">
            <div className="modal-overlay" onClick={closeModal} />
            <div className="modal-contents">
              <div className="modal-heading">{props.modalTimeProp.title}</div>
              <div className="modal-body scroll-select-box">
                <div className="scroll-select-inner is-time-select">
                {
                  hourList.length > 0 &&
                  <ul ref={hourUl} onScroll={hourScroll}>
                    {
                      hourList.map((hour: string) => (
                        <li key={hour} data-value={hour}>{hour}<span>時</span></li>
                      ))
                    }
                  </ul>
                }
                {
                  minuteList.length > 0 &&
                  <ul ref={minuteUl} onScroll={minuteScroll}>
                    {
                      minuteList.map((minute: string) => (
                        <li key={minute} data-value={minute}>{minute}<span>分</span></li>
                      ))
                    }
                  </ul>
                }
                </div>
              </div>
              <div className="modal-footer">
                <div className="app-box-button">
                  <div className="button-wrap" onClick={settingClick}>
                    {/* <div className='button-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
                      <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
                    </svg>
                  </div> */}
                    <div className='button-label'>{props.buttonLabel}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : ("")
      }
    </>
  )
}
