import { FC, useState, useCallback, useEffect } from "react";
import { DoughnutPieChart } from "../components/doughnutPieChart";
import { ClubLensInfo, getLensTypeText, getExchangeDays, exchangeLens, ctreateClubLensInfo } from 'types/ClubLensInfo';
import { saveClubLensInfo, deleteClubLensInfo, checkAuthError } from 'utils/apis/ClubLensInfo'
import { ModalReset } from "../components/modal/ModalReset"
import { ModalMessage, ModalMessageProp } from "../components/modal/ModalMessage"
import { ModalComment } from "../components/modal/ModalComment"
import { ModalRenew } from "components/modal/ModalRenew";


interface Props {
  clubLensInfo: ClubLensInfo;
  setClubLensInfo: (clubLensInfo: ClubLensInfo) => void;
  setCurrentPpage: (currentPpage: "" | "TOP" | "SETTING" | "ERROR") => void;
  setFirstAccess: (firstAccess: boolean) => void;
  setAuthErrorTrue: () => void;
  showModalComment: boolean;
  setShowModalComment: (showModalComment:boolean) => void;
  modalCommentMsg: string;
  showComment: (modalCommentMsg: string) => void;
}

export const D2: FC<Props> = ({ clubLensInfo, setClubLensInfo, setCurrentPpage, setFirstAccess, setAuthErrorTrue, showModalComment, setShowModalComment, modalCommentMsg, showComment }) => {
  const [today, setToday] = useState(new Date());
  const [exchangeDays, setExchangeDays] = useState(getExchangeDays(clubLensInfo));
  setInterval(() => {
    const tmpDate= new Date();
    if (today.getDate() !== tmpDate.getDate()){
      setExchangeDays(getExchangeDays(clubLensInfo));
      setToday(tmpDate);
    }
  },5000)
  useEffect(() => {
    setExchangeDays(getExchangeDays(clubLensInfo));
  }, [clubLensInfo])
  useEffect(() =>{
    if (exchangeDays <= 0) {
      showComment("期限が切れています\nレンズを交換してください");
    }
  }, [exchangeDays])

  //初期化
  const [showModalReset, setShowModalReset] = useState(false);

  //レンズ交換確認
  const [showModalRenew, setShowModalRenew] = useState(false);

  //トーストメッセージ
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [modalMessageProp, setModalMessageProp] = useState<ModalMessageProp>({ message: "", error: false, success: false });
  const showMessage = (message: string, error: boolean, success: boolean) => {
    setModalMessageProp({
      message: message,
      error: error,
      success: success
    });
    setShowModalMessage(true);
  }

  const exchangeLensClickErrorMsg = "レンズ交換でエラーが発生しました";
  const exchangeLensClick = () => {
    setShowModalComment(false);
    setShowModalRenew(true);
  };
  const exchangeLensProcess = useCallback((func: () => void) => {
    const clubLensInfoExchange = exchangeLens(clubLensInfo);
    saveClubLensInfo(clubLensInfoExchange).then(() => {
      setClubLensInfo(clubLensInfoExchange);
      showComment("レンズを交換しました！");
      setShowModalRenew(false);
    }).catch((error) => {
      if (error.response) {
        if (checkAuthError(error.response)) {
          setAuthErrorTrue();
        } else {
          showMessage(exchangeLensClickErrorMsg, true, false);
        }
      } else {
        showMessage(exchangeLensClickErrorMsg, true, false);
      }
      setShowModalRenew(false);
    }).finally(() => {
      func();
    });
  }, [clubLensInfo, setClubLensInfo, setShowModalRenew, setAuthErrorTrue])

  const initializeClickErrorMsg = "初期化でエラーが発生しました";
  const initializeClick = () => {
    setShowModalComment(false);
    setShowModalReset(true);
  }

  const initializeProcess = useCallback((func: () => void) => {
    deleteClubLensInfo().then((data) => {
      setFirstAccess(true);
      setClubLensInfo(ctreateClubLensInfo());
      setShowModalReset(false);
    }).catch((error) => {
      if (error.response) {
        if (checkAuthError(error.response)) {
          setAuthErrorTrue();
        } else {
          showMessage(initializeClickErrorMsg, true, false);
        }
      } else {
        showMessage(initializeClickErrorMsg, true, false);
      }
      setShowModalReset(false);
    }).finally(() => {
      func();
    });
  }, [setFirstAccess, setClubLensInfo, setShowModalReset, setAuthErrorTrue]);

  const changeSettingsClick = () => {
    setShowModalComment(false);
    setCurrentPpage("SETTING");
  };

  return (
    <>
      <div className="app-box is-top-app">
        <div className="app-box-heading">{getLensTypeText(clubLensInfo)}</div>
        <div className="app-box-body">
          <div className={`app-chart-box ${exchangeDays <= 0 ? "is-expired" : ""}`}>
            <DoughnutPieChart 
              data1={exchangeDays}
              data2={Math.floor((clubLensInfo.exchange_date.getTime() - clubLensInfo.start_date.getTime()) / 86400000) - exchangeDays}
            />
            <div className="chart-box">
              <div className="chart-box-inner">
                <div className="chart-title">交換まで</div>
                <div className="chart-number-wrap">
                  <div className="chart-number-text">あと</div>
                  <div className="chart-number-count">{exchangeDays}</div>
                  <div className="chart-number-day">日</div>
                </div>
                <ul className="chart-date-wrap">
                  <li className="is-start-label">
                    <div className="chart-date-label">開始</div>
                    <div className="chart-date">{clubLensInfo.start_date.getFullYear()}.{clubLensInfo.start_date.getMonth() + 1}.{clubLensInfo.start_date.getDate()}</div>
                  </li>
                  <li className="is-end-label">
                    <div className="chart-date-label">交換</div>
                    <div className="chart-date">{clubLensInfo.exchange_date.getFullYear()}.{clubLensInfo.exchange_date.getMonth() + 1}.{clubLensInfo.exchange_date.getDate()}</div>
                  </li>
                </ul>
              </div>
              <ModalComment
                showFlag={showModalComment}
                setShowModalComment={setShowModalComment}
                comment={modalCommentMsg}
              />
            </div>
          </div>
        </div>
        <div className="app-box-button">
          <div className="button-wrap" onClick={exchangeLensClick}>
            <div className='button-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
                <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
              </svg>
            </div>
            <div className='button-label'>レンズを<br />交換する</div>
          </div>
          <div className="button-wrap" onClick={changeSettingsClick}>
            <div className='button-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </div>
            <div className='button-label'>設定を<br />変更する</div>
          </div>
        </div>
        <div className="app-box-menu">
          <div className="menu-box" onClick={initializeClick}>
            <div className="menu-box-icon">
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eraser" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M19 20h-10.5l-4.21 -4.3a1 1 0 0 1 0 -1.41l10 -10a1 1 0 0 1 1.41 0l5 5a1 1 0 0 1 0 1.41l-9.2 9.3"></path>
                <path d="M18 13.3l-6.3 -6.3"></path>
              </svg>
            </div>
            <div className="menu-box-label">初期化</div>
          </div>
        </div>
      </div>
      <ModalReset
        showFlag={showModalReset}
        setShowModalReset={setShowModalReset}
        initializeProcess={initializeProcess}
      />
      <ModalRenew
        showFlag={showModalRenew}
        setShowModalRenew={setShowModalRenew}
        exchangeLensProcess={exchangeLensProcess}
      />
      <ModalMessage
        showFlag={showModalMessage}
        setShowModalMessage={setShowModalMessage}
        modalMessageProp={modalMessageProp}
      />
    </>
  );
}

export default D2;
