import { useState, useEffect, useCallback } from 'react';
import {Layout} from './components/Layout';
import D2 from './page/D2';
import Setting from './page/Setting';
import Error from './page/Error';
import Loader from './components/Loader';
import { getClubLensInfo, checkAuthError } from './utils/apis/ClubLensInfo'
import { ClubLensInfo, ctreateClubLensInfo, calendarDisplayFlg } from './types/ClubLensInfo'

export const App = () => {
  const [clubLensInfo, setClubLensInfo] = useState<ClubLensInfo>();
  const [loaded, setLoaded] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [firstAccess, setFirstAccess] = useState(false);
  const [currentPpage, setCurrentPpage] = useState<"" | "TOP" | "SETTING" | "ERROR">("");
  const [showModalComment, setShowModalComment] = useState(false);
  const [modalCommentMsg, setModalCommentMsg] = useState("");
  const [pageShow, setPageShow] = useState<Date>();
  const showComment = useCallback((msg: string) => {
    setModalCommentMsg(msg);
    setShowModalComment(true);
  }, [setModalCommentMsg, setShowModalComment])
  const setAuthErrorTrue = useCallback(() => {
    setAuthError(true);
  }, [setAuthError]);

  if (navigator.userAgent.match('iPhone')) {
    window.onpopstate = () => {
      const now = new Date();
      if (pageShow && now.getTime() - pageShow.getTime() > 5000) {
        setPageShow(now);
      }
    }
  } 

  window.onpageshow = () => {
    const now = new Date();
    if (pageShow && now.getTime() - pageShow.getTime() > 5000) {
      setPageShow(now);
    }
  }

  useEffect(() =>{
    if (!pageShow) {
      setPageShow(new Date());
      return;
    }
    if (currentPpage === "SETTING") {
      return;
    }
    setLoaded(false);
    getClubLensInfo().then((clubLensInfo: ClubLensInfo | undefined) => {
      if (clubLensInfo) {
        clubLensInfo.calendar_display_flg = calendarDisplayFlg(clubLensInfo);
        setClubLensInfo(clubLensInfo);
        setCurrentPpage("TOP");
      } else {
        setFirstAccess(true);
        setClubLensInfo(ctreateClubLensInfo());
      }
    }).catch((error) => {
      if (error.response && checkAuthError(error.response)) {
        setAuthErrorTrue();
      } else {
        setCurrentPpage("ERROR");
      }
    }).finally(() => {
      setLoaded(true);
    });
  }, [pageShow])

  useEffect(()=>{
    if (!loaded) {
      setCurrentPpage("");
    } else if (authError) {
      setCurrentPpage("ERROR");
    } else if (firstAccess) {
      setCurrentPpage("SETTING");
    }
  }, [loaded, authError, firstAccess]);

  const [errorDateime, setErrorDateime] = useState<Date>();
  useEffect(() => {
    if (currentPpage === "ERROR") {
      setErrorDateime(new Date());
    }
  }, [currentPpage]);


  return (
    <div className="App">
      <Layout>
        {
          currentPpage === "TOP" && clubLensInfo && 
          <D2
            clubLensInfo={clubLensInfo}
            setClubLensInfo={setClubLensInfo}
            setCurrentPpage={setCurrentPpage}
            setAuthErrorTrue={setAuthErrorTrue}
            setFirstAccess={setFirstAccess}
            showModalComment={showModalComment}
            setShowModalComment={setShowModalComment}
            modalCommentMsg={modalCommentMsg}
            showComment={showComment}
          />
        }
        {
          currentPpage === "SETTING" && clubLensInfo && 
          <Setting
            clubLensInfo={clubLensInfo}
            firstAccess={firstAccess}
            setAuthErrorTrue={setAuthErrorTrue}
            setClubLensInfo={setClubLensInfo}
            setCurrentPpage={setCurrentPpage}
            setFirstAccess={setFirstAccess}
            showComment={showComment}
          />
        }
        {
          currentPpage === "ERROR" && 
          <Error 
            authError={authError}
            accrualDatetime={errorDateime}
            pageShow={pageShow}
            setPageShow={setPageShow}
          />
        }
        {
          currentPpage === "" && 
          <Loader />
        }
      </Layout>
    </div>
  );
}

export default App;
