import { FC, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend);


interface Props {
  data1: number
  data2: number
}
export const DoughnutPieChart: FC<Props> = ({data1, data2}) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [data1, data2],
        backgroundColor: [
          'rgba(239, 203, 68, 1)',
          'rgba(249, 237, 207, 1)',
        ],
        borderColor: [
          'rgba(239, 203, 68, 1)',
          'rgba(239, 203, 68, 1)',
        ],
        borderWidth: 0,
      },
    ],
  });
  useEffect(() => {
    setData({
      labels: [],
      datasets: [
        {
          data: [data1, data2],
          backgroundColor: [
            'rgba(239, 203, 68, 1)',
            'rgba(249, 237, 207, 1)',
          ],
          borderColor: [
            'rgba(239, 203, 68, 1)',
            'rgba(239, 203, 68, 1)',
          ],
          borderWidth: 0,
        },
      ],
    });
  }, [data1, data2]);
  return <Doughnut
            data={data}
            className="chart-box-canvas"
          />;
}
