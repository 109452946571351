import { FC, ReactNode} from 'react';
import '../assets/style/style.scss';

type Props = {
    children: ReactNode
}

export const Layout:FC<Props> = ({children}) => {
  return (
    <div className="main-wrap">
        <div className="app-wrap">
            <div className="app-wrap-inner">
                {children}
            </div>
        </div>
    </div>
  )
}
