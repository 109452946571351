import { FC, useState } from 'react';

type Props = {
  showFlag?: boolean
  setShowModalRenew: (arg: boolean) => void;
  exchangeLensProcess: (func:() => void) => void;
}

export const ModalRenew: FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const closeModal = () =>{
    props.setShowModalRenew(false);
  }

  const exchangeLens = () => {
    setLoading(true);
    props.exchangeLensProcess(() => { setLoading(false) });
  }

  return (
    <>
      {
        props.showFlag ? (
          <>
            {
              loading ?
              <div className="modal-wrap">
                <div className="modal-overlay" />
                <div className="modal-contents">
                  <div className="modal-heading">
                  レンズを交換する
                  </div>
                  <div className="modal-body">
                  交換日数をリセットし、本日から使用開始日として設定します<br />レンズを交換しますか？
                  </div>
                  <div className="modal-footer">
                    <div className="app-box-button loading-button">
                      <div className='loading-bar-wrap'>
                        <div className="loading-race-by"></div>
                      </div>
                      <div className="button-wrap is-disabled">
                        <div className='button-label'>キャンセル</div>
                      </div>
                      <div className="button-wrap">
                        <div className='button-label-icon'>
                          <svg
                            className="loading-ring"
                            viewBox="25 25 50 50"
                            strokeWidth="5"
                          >
                            <circle cx="50" cy="50" r="20" />
                          </svg>
                        </div>
                        <div className='button-label'>
                          交換中...
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            :
            <div className="modal-wrap">
              <div className="modal-overlay" onClick={closeModal}/>
              <div className="modal-contents">
                <div className="modal-heading">
                レンズを交換する
                </div>
                <div className="modal-body">
                交換日数をリセットし、本日から使用開始日として設定します<br />レンズを交換しますか？
                </div>
                <div className="modal-footer">
                  <div className="app-box-button">
                    <div className="button-wrap" onClick={closeModal}>
                      <div className='button-label'>キャンセル</div>
                    </div>
                    <div className="button-wrap" onClick={exchangeLens}>
                    <div className='button-label'>交換する</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            }
          </>
        ) : ("")
      }
    </>
  )
}
