import { FC, useEffect, useRef, useState} from 'react';

export interface ModalDateProp {
  dateArray: Date[];
  date: Date;
  setDate: (date: Date) => void;
  title: string
}

type Props = {
  showFlag?: boolean
  setShowModal: (arg:boolean) => void;
  buttonLabel: string
  modalDateProp: ModalDateProp
}

export const ModalDate: FC<Props> = (props) => {
  const [year, setYear] = useState(props.modalDateProp.date.getFullYear());
  const [month, setMonth] = useState(props.modalDateProp.date.getMonth());
  const [date, setDate] = useState(props.modalDateProp.date.getDate());
  const [yearList, setYearList] = useState<number[]>([]);
  const [monthList, setMonthList] = useState<number[]>([]);
  const [dateList, setDateList] = useState<number[]>([]);

  useEffect(() => {
    const yearList = Array.from(new Set(props.modalDateProp.dateArray.map((date) => { return date.getFullYear() }))).sort((a, b) => a - b);
    const year = yearList.indexOf(props.modalDateProp.date.getFullYear()) < 0 ? yearList[0] : props.modalDateProp.date.getFullYear();

    const monthList = Array.from(new Set(props.modalDateProp.dateArray.filter((date) => { return date.getFullYear() === year }).map((date) => { return date.getMonth() }))).sort((a, b) => a - b);
    const month = yearList.indexOf(year) < 0 || monthList.indexOf(props.modalDateProp.date.getMonth()) < 0 ? monthList[0] : props.modalDateProp.date.getMonth();

    const dateList = Array.from(new Set(props.modalDateProp.dateArray.filter((date) => { return date.getFullYear() === year && date.getMonth() === month }).map((date) => { return date.getDate() }))).sort((a, b) => a - b);

    setYearList(yearList);
    setMonthList(monthList);
    setDateList(dateList);

    setYear(year);
    setMonth(month);
    if (yearList.indexOf(year) < 0 || monthList.indexOf(month) < 0 || dateList.indexOf(props.modalDateProp.date.getDate()) < 0) {
      setDate(dateList[0]);
    } else {
      setDate(props.modalDateProp.date.getDate());
    }
  }, [props.modalDateProp]);
  useEffect(() => {
    const monthList = Array.from(new Set(props.modalDateProp.dateArray.filter((date) => { return date.getFullYear() === year }).map((date) => { return date.getMonth() }))).sort((a, b) => a - b);
    if (monthList.indexOf(month) < 0) {
      setMonth(monthList[0]);
    }
    setMonthList(monthList);
  }, [year]);
  useEffect(() => {
    const dateList = Array.from(new Set(props.modalDateProp.dateArray.filter((date) => { return date.getFullYear() === year && date.getMonth() === month }).map((date) => { return date.getDate() }))).sort((a, b) => a - b);
    if (dateList.indexOf(date) < 0) {
      setDate(dateList[0]);
    }
    setDateList(dateList);
  }, [month]);

  const [yearScrollTo, setYearScrollTo] = useState(false);
  const [monthScrollTo, setMonthScrollTo] = useState(false);
  const [dateScrollTo, setDateScrollTo] = useState(false);
  const yearUl = useRef<HTMLUListElement>(null);
  const monthUl = useRef<HTMLUListElement>(null);
  const dateUl = useRef<HTMLUListElement>(null);
  useEffect(() =>{
    if (yearUl && yearUl.current) {
      scroll(yearUl, year, setYearScrollTo);
    }
  }, [yearUl.current, yearList])
  useEffect(() => {
    if (monthUl && monthUl.current) {
      scroll(monthUl, month, setMonthScrollTo);
    }
  }, [monthUl.current, monthList])
  useEffect(() => {
    if (dateUl && dateUl.current) {
      scroll(dateUl, date, setDateScrollTo);
    }
  }, [dateUl.current, dateList])

  const scroll = (ulElement: any, value: number, setScrollTo: (scrollTo:boolean) => void) => {
    const ul = ulElement.current;
    Array.from(ul.children).forEach((li: any) => {
      const dataValue = Number(li.getAttribute("data-value"));
      if (value === dataValue) {
        setScrollTo(true);
        setTimeout(() => {
          ul.scrollTo({
            behavior: "instant",
            top: (li.offsetTop - 112),
          });
        }, 0);
      }
    });
  }

  const yearScroll = (event: any) => {
    if (yearScrollTo) {
      setTimeout(() => {
        setYearScrollTo(false);
      }, 10);
      return;
    }
    scrollFunc(event.target, setYear);
  }
  const monthScroll = (event:any) => {
    if (monthScrollTo) {
      setTimeout(() => {
        setMonthScrollTo(false);
      }, 10);
      return;
    }
    scrollFunc(event.target, setMonth);
  }
  const dateScroll = (event: any) => {
    if (dateScrollTo) {
      setTimeout(() => {
        setDateScrollTo(false);
      }, 10);
      return;
    }
    scrollFunc(event.target, setDate);
  }

  const scrollFunc = (ul: any, setFunc: (val:number) => void) => {
    if (ul) {
      const ulBoundingClientRect = ul.getBoundingClientRect();
      const ulOffsetHeight = ul.offsetHeight;
      const middle = ulBoundingClientRect.top + (ulOffsetHeight / 2);
      Array.from(ul.children).forEach((li: any) => {
        const liBoundingClientRect = li.getBoundingClientRect();
        if (liBoundingClientRect.top < middle && liBoundingClientRect.bottom > middle) {
          const dataValue = Number(li.getAttribute("data-value"));
          setFunc(dataValue);
        }
      });
    }
  }

  const settingClick = () => {
    props.modalDateProp.setDate(new Date(year, month, date));
    props.setShowModal(false);
  }
  const closeModal = () =>{
    props.setShowModal(false);
  }
  return (
    <>
    {
      props.showFlag
      ?(
        <div className="modal-wrap">
          <div className="modal-overlay" onClick={closeModal}/>
          <div className="modal-contents">
                <div className="modal-heading">{props.modalDateProp.title}</div>
            <div className="modal-body scroll-select-box">
              <div className="scroll-select-inner is-date-select">
              {
                yearList.length > 0 &&
                <ul ref={yearUl} onScroll={yearScroll}>
                  {
                    yearList.map((year:number) => (
                      <li key={year} data-value={year}>{year}<span>年</span></li>
                    ))
                  }
                </ul>
              }
              {
                monthList.length > 0 &&
                <ul ref={monthUl} onScroll={monthScroll}>
                  {
                    monthList.map((month:number) => (
                      <li key={month} data-value={month}>{month+1}<span>月</span></li>
                    ))
                  }
                </ul>
              }
              {
                dateList.length > 0 &&
                <ul ref={dateUl} onScroll={dateScroll}>
                  {
                    dateList.map((date:number) => (
                      <li key={date} data-value={date}>{date}<span>日</span></li>
                    ))
                  }
                </ul>
              }
              </div>
            </div>
            <div className="modal-footer">
              <div className="app-box-button">
                <div className="button-wrap" onClick={settingClick}>
                  {/* <div className='button-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
                      <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
                    </svg>
                  </div> */}
                  <div className='button-label'>{props.buttonLabel}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      :("")
    }
    </>
  )
}