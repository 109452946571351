const daybefore_time_default = "17:00";
const today_time_default = "08:00";

export type ClubLensInfo = {
    lens_type_cd: string;
    start_date: Date;
    exchange_date: Date;
    daybefore_notice_datetime: string;
    today_notice_datetime: string;
    calendar_display_flg: number;
}

export const ctreateClubLensInfo = (): ClubLensInfo =>{
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const exchangeDate = calcExchangeDateMax("1", today);
    const daybefore = new Date(exchangeDate.getFullYear(), exchangeDate.getMonth(), exchangeDate.getDate());
    daybefore.setDate(daybefore.getDate() - 1);
    return {
        lens_type_cd : "1",
        start_date: today,
        exchange_date: exchangeDate,
        daybefore_notice_datetime: `${daybefore.getFullYear()}/${daybefore.getMonth()}/${daybefore.getDate()}T${daybefore_time_default}`,
        today_notice_datetime: `${exchangeDate.getFullYear()}/${exchangeDate.getMonth()}/${exchangeDate.getDate()}T${today_time_default}`,
        calendar_display_flg: (window.screen.height) >= 800 ? 0 : 1,
    }
}

export const calendarDisplayFlg = (clubLensInfo: ClubLensInfo): number => {
    if (clubLensInfo.calendar_display_flg === 0 || clubLensInfo.calendar_display_flg === 1) {
        return clubLensInfo.calendar_display_flg;
    }
    return (window.screen.height) >= 800 ? 0 : 1;
}

export const getLensTypeText = (clubLensInfo: ClubLensInfo): string => {
    switch (clubLensInfo.lens_type_cd) {
        case "1":
            return "2WEEK";
        case "2":
            return "1MONTH";
        case "3":
            return "3MONTH";
        default:
            return "";
    }
}

export const getDaybeforeTime = (clubLensInfo: ClubLensInfo): string => {
    if (clubLensInfo.daybefore_notice_datetime) {
        return clubLensInfo.daybefore_notice_datetime.substring(clubLensInfo.daybefore_notice_datetime.indexOf("T") + 1);
    }
    return daybefore_time_default;
}

export const getTodayTime = (clubLensInfo: ClubLensInfo): string => {
    if (clubLensInfo.today_notice_datetime) {
        return clubLensInfo.today_notice_datetime.substring(clubLensInfo.today_notice_datetime.indexOf("T") + 1);
    }
    return today_time_default;
}

export const getExchangeDateMax = (clubLensInfo: ClubLensInfo): Date => {
    return calcExchangeDateMax(clubLensInfo.lens_type_cd, clubLensInfo.start_date);
}

export const getExchangeDays = (clubLensInfo: ClubLensInfo): number => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const exchangeDays = Math.floor((clubLensInfo.exchange_date.getTime() - today.getTime()) / 86400000);
    return Math.max(0, exchangeDays);
}

export const exchangeLens = (clubLensInfo: ClubLensInfo): ClubLensInfo => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const exchangeDate = calcExchangeDateMax(clubLensInfo.lens_type_cd, today);
    const daybefore = new Date(exchangeDate.getFullYear(), exchangeDate.getMonth(), exchangeDate.getDate());
    daybefore.setDate(daybefore.getDate() - 1);
    return {
        lens_type_cd: clubLensInfo.lens_type_cd,
        start_date: today,
        exchange_date: exchangeDate,
        daybefore_notice_datetime: clubLensInfo.daybefore_notice_datetime ? `${convertDateToStr(daybefore)}T${getDaybeforeTime(clubLensInfo)}` : "",
        today_notice_datetime: clubLensInfo.today_notice_datetime ? `${convertDateToStr(exchangeDate)}T${getTodayTime(clubLensInfo)}` : "",
        calendar_display_flg: clubLensInfo.calendar_display_flg,
    }
}

export const convertDateToStr = (date: Date):string => {
    const str = `${date.getFullYear()}-${("00" + (date.getMonth() + 1)).slice(-2)}-${("00" + date.getDate()).slice(-2)}`;
    return str;
}

export const calcExchangeDateMax = (lens_type_cd: string, start_date: Date): Date => {
    const exchangeDateMax = new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate());
    switch (lens_type_cd) {
        case "1":
            exchangeDateMax.setDate(exchangeDateMax.getDate() + 14);
            break;
        case "2":
            exchangeDateMax.setMonth(exchangeDateMax.getMonth() + 1);
            if (start_date.getDate() !== exchangeDateMax.getDate()) {
                exchangeDateMax.setDate(0);
            }
            break;
        case "3":
            exchangeDateMax.setMonth(exchangeDateMax.getMonth() + 3);
            if (start_date.getDate() !== exchangeDateMax.getDate()) {
                exchangeDateMax.setDate(0);
            }
            break;
        default:
            break;
    }
    return exchangeDateMax;
}

export const calcStartDateMin = (lens_type_cd: string, now: Date): Date => {
    const startDateMin = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    switch (lens_type_cd) {
        case "1":
            startDateMin.setDate(startDateMin.getDate() - 14);
            break;
        case "2":
            startDateMin.setMonth(startDateMin.getMonth() - 1);
            if (now.getDate() !== startDateMin.getDate()) {
                startDateMin.setDate(0);
            }
            break;
        case "3":
            startDateMin.setMonth(startDateMin.getMonth() - 3);
            if (now.getDate() !== startDateMin.getDate()) {
                startDateMin.setDate(0);
            }
            break;
        default:
            break;
    }
    return startDateMin;
}