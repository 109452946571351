import { FC } from 'react';


export interface ModalMessageProp {
  message: string;
  error: boolean;
  success: boolean;
}
type Props = {
  showFlag?: boolean
  setShowModalMessage: (arg: boolean) => void;
  modalMessageProp: ModalMessageProp;
}

export const ModalMessage: FC<Props> = (props) => {
  const closeModal = () =>{
    props.setShowModalMessage(false);
  }
  return (
    <>
      {
        props.showFlag ? (
          <div className="modal-wrap">
            {/* <div className="modal-overlay" onClick={closeModal}/> */}
            <div className={`toast-contents 
              ${props.modalMessageProp.error ? "is-error" : ""}
              ${props.modalMessageProp.success ? "is-success" : ""}
            `}>
              {/* <div className="toast-heading">
                {props.title}
              </div> */}
              <div className="toast-close" onClick={closeModal} >
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              <div className="toast-body">
              {props.modalMessageProp.message}
              </div>
            </div>
          </div>
        ) : ("")
      }
    </>
  )
}
